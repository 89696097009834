(function () {
	'use strict';
	angular.module('eventvods')
		.constant('ElementBGCount', 29)
		.factory('FeaturedService', ['$http', 'API_BASE_URL', function ($http, API_BASE_URL) {
			return {
				get: function () {
					return $http.get(API_BASE_URL + '/featured');
				}
			};
		}])
		.factory('GamesList', [(function () {
			return {
				"overwatch": "Overwatch",
				"lol": "League of Legends",
				"csgo": "CS:GO",
				"dota": "Dota2",
				"rocket-league": "Rocket League",
				"pubg": "PlayerUnknowns's Battlegrounds",
				"valorant": "VALORANT",
				null: ""
			}
		})])
		.controller('FeaturedController', ['FeaturedService', 'GamesList', '$location', '$rootScope', '$window','$timeout', 'ElementBGCount',
			function (FeaturedService, GamesList, $location, $rootScope, $window, $timeout, ElementBGCount) {
				$window.prerenderReady = false;
				var vm = this;
				// Data
				vm.events = [], vm.following = [];
				vm.followLimit = 4, vm.eventLimit = 8;
				vm.show = false;
				var randomOrder = Array.from(new Array(ElementBGCount).join(".").split(".").keys()).sort(function(a, b){return 0.5 - Math.random()});
				vm.games = GamesList;

				// Selected game
				vm.game = ($rootScope.oldGame == 'static' ? null : $rootScope.oldGame);

				var path_game = $location.path().replace('/featured/', '');
				if (path_game in GamesList) vm.game = path_game;
				$rootScope.game = vm.game;

				function setTitle() {
					$timeout(function () {
						if (vm.game === null) {
							document.title = $rootScope.meta.title = "Eventvods - Esports on Demand";
							$rootScope.meta.description = "Watch esports matches on demand easily and spoiler-free on Eventvods. Follow your favorite League of Legends, CS:GO, Dota & Overwatch teams.";
						} else {
							document.title = $rootScope.meta.title = "Featured " + GamesList[vm.game] + " Events - Eventvods";
							$rootScope.meta.description = "Find and watch current " + GamesList[vm.game] + " esports events easily and spoiler-free. Rate matches of your favorite teams and share them with friends."
						}
					}, 0);
				}
				setTitle();

				vm.gameFilter = function (item) {
					return (vm.game == null || item.game.slug == vm.game);
				}

				//Sorting
				vm.sorts = [
					{
						"text": "Featured",
						"field": "$index",
						reverse: false
					},
					{
						"text": "Last Updated",
						"field": "updatedAt",
						reverse: true
					},
					{
						"text": "Latest Events",
						"field": "sortDate",
						reverse: true
					},
					{
						"text": "Most Popular",
						"field": "followers",
						reverse: true
					}
				];
				vm.sortFollowing = vm.sorts[1];
				vm.sortFeatured = vm.sorts[0];

				vm.elementClass = function($index) {
					var img = randomOrder[($index % ElementBGCount)] + 1;
                    return "element-" + img;
                }

				vm.toggleGame = function (game) {
					vm.show = false;
					if (game == vm.game || game == null) {
						vm.game = null;
						$location.path('/', false);
					} else if (game in GamesList) {
						vm.game = game;
						$location.path('/featured/' + game, false);
					} else {
						vm.game = null;
						$location.path('/', false);
					}
					$rootScope.game = vm.game;
					setTitle();
					vm.show = true;
					$timeout(function () {
						$('[data-toggle="tooltip"]').tooltip('dispose');
						$('[data-toggle="tooltip"]').tooltip();
					}, 0);
				}
				vm.pushFollow = function (id) {
					var event = vm.events.filter(function (item) {
						return item._id == id;
					})[0];
					vm.following.push(event);
				}
				vm.spliceFollow = function (id) {
					var event = vm.following.filter(function (item, index) {
						item.index = index;
						return item._id == id;
					})[0];
					vm.following.splice(event.index, 1);
				}
				$rootScope.$on('featuredReset', function () {
					vm.toggleGame(null);
					$rootScope.oldGame = null;
					$timeout(function () {
						$('[data-toggle="tooltip"]').tooltip('dispose');
						$('[data-toggle="tooltip"]').tooltip();
					}, 0);
				});

				function load() {
					FeaturedService.get()
						.then(function (res) {
							vm.events = (res.data.events || []).map(function (event) {
								event.diff = 0;
								event.sortDate = event.endDate;
								event.updateString = "Last updated at " + $window.moment.utc(event.updatedAt).format("MMMM Do YYYY, h:mm A");
								return event;
							});
							vm.following = (res.data.following || []).map(function (event) {
								event.diff = 0;
								event.sortDate = event.endDate;
								event.updateString = "Last updated at " + $window.moment.utc(event.updatedAt).format("MMMM Do YYYY, h:mm A");
								return event;
							});
							vm.show = true;
							$timeout(function () {
								$('[data-toggle="tooltip"]').tooltip();
								$('.element-container a').click(function (evt) {
									var el = $(this).closest('.element-border').attr('class').split(" ")[1];
									$rootScope.$apply(function () {
										$rootScope.oldGame = $rootScope.game;
										$rootScope.game = el;
									});
								});
								$window.prerenderReady = true;
							}, 1);
						});
				};
				load();
				$rootScope.$on('reloadFeatured', load);
			}
		]);
}());
